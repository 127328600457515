/**
 * Escapes special characters in a string to be used in a regular expression.
 *
 * @param string - The string to escape.
 * @return The escaped string.
 */
const escapeRegExp = (string: string): string =>
  string.replace(/[.*+?^${}()|[\]\\-]/g, '\\$&')

/**
 * Converts an str string to a regex pattern.
 *
 * The function converts continuous sequences of letters into a pattern like
 * `[A-Za-z]{n}` and digits into `\d{n}`. Additionally, any substring wrapped
 * in double curly braces (e.g. `{{pq}}`) is treated as a literal part of the
 * regex, so it won't be converted.
 *
 * For str, an input like:
 *   "{{pq}}-000-00-00--000"
 *
 * Will treat "pq" as a static literal, while converting sequences of "0" into
 * their respective digit patterns.
 *
 * @param string - The str string.
 * @return The regex pattern string.
 */
export const stringToRegex = (str: string): string => {
  const parts: string[] = []

  // We'll build in chunks: a chunk of letters, a chunk of digits, or single special chars
  let currentMode: 'letter' | 'digit' | null = null
  let chunkCount = 0

  // Helper to flush whatever chunk we've built
  function flushChunk(): void {
    if (chunkCount > 0) {
      if (currentMode === 'letter') {
        parts.push(`[A-Za-z]{${chunkCount}}`)
      } else if (currentMode === 'digit') {
        parts.push(`\\d{${chunkCount}}`)
      }
    }
    currentMode = null
    chunkCount = 0
  }

  let i = 0
  while (i < str.length) {
    // Check if a literal segment starts with '{{'
    if (str[i] === '{' && i + 1 < str.length && str[i + 1] === '{') {
      flushChunk()
      // Find the closing '}}'
      const endIdx = str.indexOf('}}', i + 2)
      if (endIdx === -1) {
        // No closing found: treat the rest of the string as literal
        const literal = str.slice(i)
        parts.push(escapeRegExp(literal))
        break
      } else {
        // Extract the literal text between '{{' and '}}'
        const literal = str.slice(i + 2, endIdx)
        parts.push(escapeRegExp(literal))
        // Move index past the closing '}}'
        i = endIdx + 2
        continue
      }
    }

    const ch = str[i]
    const code = ch.charCodeAt(0)
    // Check if it's a letter: A-Z (65-90) or a-z (97-122)
    if ((code >= 65 && code <= 90) || (code >= 97 && code <= 122)) {
      if (currentMode === 'letter') {
        chunkCount++
      } else {
        flushChunk()
        currentMode = 'letter'
        chunkCount = 1
      }
    }
    // Check if it's a digit: 0-9 (48-57)
    else if (code >= 48 && code <= 57) {
      if (currentMode === 'digit') {
        chunkCount++
      } else {
        flushChunk()
        currentMode = 'digit'
        chunkCount = 1
      }
    }
    // Otherwise, it's a special character or something else
    else {
      flushChunk()
      parts.push(escapeRegExp(ch))
    }
    i++
  }

  flushChunk()

  // Join all parts and anchor the pattern
  const result = parts.join('')
  return `^${result}$`
}

/**
 * Converts a regex pattern (as produced by stringToRegex) back into an example string.
 * It handles digit groups (\d{n}), letter groups ([A-Za-z]{n}), and escaped literals.
 *
 * @param pattern - The regex pattern string.
 * @returns An example string that matches the regex.
 */
export const regexToString = (pattern: string): string => {
  // Remove the starting '^' and ending '$' if present
  if (pattern.startsWith('^')) {
    pattern = pattern.slice(1)
  }
  if (pattern.endsWith('$')) {
    pattern = pattern.slice(0, -1)
  }

  let result = ''

  while (pattern.length > 0) {
    // Match a digit group: \d{n}
    const digitMatch = pattern.match(/^\\d\{(\d+)\}/)
    if (digitMatch) {
      const count = parseInt(digitMatch[1], 10)
      result += '0'.repeat(count) // you could use any digit(s)
      pattern = pattern.slice(digitMatch[0].length)
      continue
    }

    // Match a letter group: [A-Za-z]{n}
    const letterMatch = pattern.match(/^\[A-Za-z\]\{(\d+)\}/)
    if (letterMatch) {
      const count = parseInt(letterMatch[1], 10)
      result += 'a'.repeat(count) // you could use any letters
      pattern = pattern.slice(letterMatch[0].length)
      continue
    }

    // Match an escaped literal (e.g. \- or \{)
    const escapedLiteralMatch = pattern.match(/^\\(.)/)
    if (escapedLiteralMatch) {
      result += escapedLiteralMatch[1]
      pattern = pattern.slice(escapedLiteralMatch[0].length)
      continue
    }

    // If none of the above, just take the first character as-is
    result += pattern[0]
    pattern = pattern.slice(1)
  }

  return result
}
