import dayjs from 'dayjs'

const ignoreCase = (str, match: RegExp) => {
  return str.replace(match, '')
}

export const sortByString = (desc: boolean, match?: RegExp) => (a, b) => {
  let aValue = a
  let bValue = b
  if (match) {
    aValue = ignoreCase(aValue, match)
    bValue = ignoreCase(bValue, match)
  }

  return aValue.localeCompare(bValue) * (desc ? -1 : 1)
}

export const sortByNumberField =
  (fieldName: string, desc = false, comparator?: (a: any, b: any) => number) =>
  (a: any, b: any): number => {
    const aValue = a[fieldName] ?? Number(a)
    const bValue = b[fieldName] ?? Number(b)

    if (aValue === bValue) {
      return comparator ? comparator(a, b) : 0
    }

    return desc ? (aValue > bValue ? -1 : 1) : aValue > bValue ? 1 : -1
  }

export const sortByStringField =
  (fieldName: string, desc?: boolean, match?: RegExp) =>
  (a, b): any => {
    const aValue =
      a[fieldName]?.toLocaleLowerCase() ?? String(a).toLocaleLowerCase() ?? ''
    const bValue =
      b[fieldName]?.toLocaleLowerCase() ?? String(b).toLocaleLowerCase() ?? ''

    return sortByString(desc, match)(aValue, bValue)
  }

export const sortByDate = (desc: boolean) => (a, b) => {
  if (desc) {
    return dayjs(a).isAfter(dayjs(b)) ? -1 : 1
  }

  return dayjs(a).isAfter(dayjs(b)) ? 1 : -1
}

export const sortByDateField = (fieldName: string, desc: boolean) => (a, b) => {
  const aValue = a[fieldName] ?? ''
  const bValue = b[fieldName] ?? ''

  return sortByDate(desc)(aValue, bValue)
}
