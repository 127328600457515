import { useState, useCallback } from 'react'
import { isBrowser } from './browser'

type UseSessionStorageReturnType<T> = [
  T,
  (value: T | ((val: T) => T)) => void,
  () => void,
]

function useSessionStorage<T>(
  key: string,
  initialValue?: T,
): UseSessionStorageReturnType<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (isBrowser()) {
        const item = window.sessionStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      }

      return initialValue
    } catch (error) {
      console.error(error)
      return initialValue
    }
  })

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)

        window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        console.error(error)
      }
    },
    [key, storedValue],
  )

  const removeValue = useCallback(() => {
    try {
      window.sessionStorage.removeItem(key)
      setStoredValue(undefined as unknown as T) // Type cast may be needed
    } catch (error) {
      console.error(error)
    }
  }, [key])

  return [storedValue, setValue, removeValue]
}

export { useSessionStorage }
