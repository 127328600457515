/**
 * Escapes glob special characters in a literal string.
 * Special glob characters include: * ? [ ] { }
 *
 * @param string - The literal string to escape.
 * @return The escaped string.
 */
const escapeGlob = (string: string): string =>
  string.replace(/([*?[\]{}])/g, '\\$1')

/**
 * Converts a custom string into a glob pattern.
 *
 * The function converts continuous sequences of letters into a series of
 * `[A-Za-z]` (one for each letter) and digits into repeated `[0-9]`.
 * Substrings wrapped in double curly braces (e.g. `{{pq}}`) are taken
 * as literals.
 *
 * For example, given the input:
 *   "00-000-00-__adsfds"
 *
 * The digit chunks (e.g. "00") become `[0-9][0-9]`, the letter chunks become
 * `[A-Za-z]` repeated, and the hyphens (or any other non-digit/letter) are escaped.
 *
 * @param str - The input string.
 * @return The corresponding glob pattern.
 */
export const stringToGlob = (str: string): string => {
  const parts: string[] = []
  let currentMode: 'letter' | 'digit' | null = null
  let chunkCount = 0

  // Flush the current chunk and append the appropriate glob segments.
  function flushChunk(): void {
    if (chunkCount > 0) {
      if (currentMode === 'letter') {
        // For each letter in the chunk, append a glob letter matcher.
        parts.push('[A-Za-z]'.repeat(chunkCount))
      } else if (currentMode === 'digit') {
        parts.push('[0-9]'.repeat(chunkCount))
      }
    }
    currentMode = null
    chunkCount = 0
  }

  let i = 0
  while (i < str.length) {
    // Check for a literal segment wrapped in double curly braces.
    if (str[i] === '{' && i + 1 < str.length && str[i + 1] === '{') {
      flushChunk()
      const endIdx = str.indexOf('}}', i + 2)
      if (endIdx === -1) {
        // If no closing is found, treat the rest as literal.
        const literal = str.slice(i)
        parts.push(escapeGlob(literal))
        break
      } else {
        // Extract the literal text between '{{' and '}}'.
        const literal = str.slice(i + 2, endIdx)
        parts.push(escapeGlob(literal))
        i = endIdx + 2
        continue
      }
    }

    const ch = str[i]
    const code = ch.charCodeAt(0)
    // Check if the character is a letter (A-Z or a-z).
    if ((code >= 65 && code <= 90) || (code >= 97 && code <= 122)) {
      if (currentMode === 'letter') {
        chunkCount++
      } else {
        flushChunk()
        currentMode = 'letter'
        chunkCount = 1
      }
    }
    // Check if it's a digit (0-9).
    else if (code >= 48 && code <= 57) {
      if (currentMode === 'digit') {
        chunkCount++
      } else {
        flushChunk()
        currentMode = 'digit'
        chunkCount = 1
      }
    }
    // For any other character, flush the current chunk and output it as a literal.
    else {
      flushChunk()
      parts.push(escapeGlob(ch))
    }
    i++
  }
  flushChunk()
  return parts.join('')
}

/**
 * Converts a glob pattern (as produced by stringToGlob) back into an example string.
 *
 * It interprets consecutive digit patterns `[0-9]` and letter patterns `[A-Za-z]`
 * and converts them back into digits ("0") and letters ("a") respectively.
 * Escaped literals are converted back into their unescaped form.
 *
 * @param pattern - The glob pattern string.
 * @returns An example string that matches the glob pattern.
 */
export const globToString = (pattern: string): string => {
  let result = ''

  while (pattern.length > 0) {
    // Check for a digit pattern: "[0-9]"
    if (pattern.startsWith('[0-9]')) {
      let count = 0
      while (pattern.startsWith('[0-9]')) {
        count++
        pattern = pattern.slice(5) // "[0-9]" is 5 characters long.
      }
      result += '0'.repeat(count)
      continue
    }

    // Check for a letter pattern: "[A-Za-z]"
    if (pattern.startsWith('[A-Za-z]')) {
      let count = 0
      while (pattern.startsWith('[A-Za-z]')) {
        count++
        pattern = pattern.slice(8) // "[A-Za-z]" is 8 characters long.
      }
      result += 'a'.repeat(count)
      continue
    }

    // Handle an escaped literal: if a backslash is present, take the next character literally.
    if (pattern.startsWith('\\')) {
      if (pattern.length >= 2) {
        result += pattern[1]
        pattern = pattern.slice(2)
        continue
      }
    }

    // Otherwise, take the first character as-is.
    result += pattern[0]
    pattern = pattern.slice(1)
  }

  return result
}
