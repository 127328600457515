import { excludeGraphQLFetch } from 'apollo-link-sentry'
import {
  init,
  Dedupe,
  Breadcrumbs,
  HttpContext,
  LinkedErrors,
  BrowserClient,
  getCurrentHub,
  defaultStackParser,
  makeFetchTransport,
  getCurrentScope,
} from '@sentry/nextjs'
import {
  SENTRY_CAPTURE_RATE,
  SENTRY_DSN,
  SENTRY_ENABLE,
} from '@features/core/sentry/sentry.constants.mjs'
import { isBrowser } from '@palqee/utils'
import Posthog from '@palqee/analytics'

const baseSentryConfig = {
  dsn: SENTRY_DSN,
  tracesSampleRate: SENTRY_CAPTURE_RATE,
  enabled: SENTRY_ENABLE,
  environment:
    process.env.SENTRY_ENVIRONMENT ||
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  release: `${process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT}@${
    process.env.CI_COMMIT_SHA ?? new Date().toISOString()
  }`,
}

const scope = getCurrentScope()
const sentryCheck = (sentryInit) => {
  if (SENTRY_DSN && SENTRY_ENABLE) {
    sentryInit()
  } else if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.error(
      'Sentry DSN not defined, events (exceptions, messages, etc.) will not be sent to Sentry.',
    )
  }
}

const sentryConfigureScope = () => {
  scope.setTag('appEnvironment', process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT)
  scope.setTag('appName', process.env.NEXT_PUBLIC_APP_NAME)
  scope.setTag('appVersion', process.env.NEXT_PUBLIC_APP_NAME_VERSION)
  scope.setTag('appBuildTime', process.env.NEXT_PUBLIC_APP_BUILD_TIME)
  scope.setTag(
    'buildTimeISO',
    new Date(process.env.NEXT_PUBLIC_APP_BUILD_TIME || null).toISOString(),
  )
  scope.setTag('appBuildId', process.env.BUILD_ID)

  if (!isBrowser()) {
    scope.setTag('nodejs', process.version)
  }

  scope.setTag('runtimeEngine', isBrowser() ? 'browser' : 'server')
}

export const sentryClientInit = () => {
  const initClient = () => {
    console.info('initializing sentry client...')
    const sentryClient = new BrowserClient({
      ...baseSentryConfig,
      // Sentry's Error Transport Mechanism
      transport: makeFetchTransport,
      // Sentry's Stack Trace Parser
      stackParser: defaultStackParser,
      beforeBreadcrumb: excludeGraphQLFetch,

      // All supported Integrations by us
      // Only the integrations listed here will be used
      integrations: [
        new Dedupe(),
        new HttpContext(),
        new Breadcrumbs(),
        new LinkedErrors(),
        Posthog &&
          (new Posthog.SentryIntegration(
            Posthog,
            process.env.SENTRY_ORG,
            Number(process.env.NEXT_PUBLIC_POSTHOG_PROJECT_ID),
          ) as any),
      ].filter((s) => s),
      // Adds custom filtering before sending an Event to Sentry
      beforeSend: (event, hint) => {
        // Attempts to grab the original Exception before any "magic" happens
        const exception = hint.originalException as Error

        // We only want to capture Errors that have a Stack Trace and that are not Anonymous Errors
        return exception?.stack && !exception.stack.includes('<anonymous>')
          ? event
          : null
      },
    })

    // Attaches this Browser Client to Sentry
    getCurrentHub().bindClient(sentryClient)
    import('@sentry/nextjs').then(({ BrowserTracing }) => {
      sentryClient.addIntegration(new BrowserTracing())
    })
    sentryConfigureScope()
  }

  sentryCheck(initClient)
}

export const sentryServerInit = () => {
  const initServer = () => {
    console.info('initializing sentry server...')
    init({
      ...baseSentryConfig,
    })
    sentryConfigureScope()
  }

  sentryCheck(initServer)
}
