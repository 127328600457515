import 'src/dialog/dialog.css.ts.vanilla.css?source=LmIzbGNlOTIgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICB3aWR0aDogMTBweDsKICBoZWlnaHQ6IDEwcHg7CiAgYmFja2dyb3VuZDogI2ZmZjsKICBsZWZ0OiB2YXIoLS1iM2xjZTkwKTsKICB0b3A6IHZhcigtLWIzbGNlOTEpOwogIHRyYW5zZm9ybTogcm90YXRlKDQ1ZGVnKTsKfQouYjNsY2U5MyB7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogNTAlOwogIGxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZSgtNTAlLCAtNTAlKTsKICB6LWluZGV4OiAxMDAwOwp9Ci5iM2xjZTk0IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7CiAgbWluLXdpZHRoOiA2MDBweDsKICBwYWRkaW5nOiAxZW07CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIG1heC1oZWlnaHQ6IDkwdmg7CiAgb3ZlcmZsb3cteTogYXV0bzsKfQouYjNsY2U5NCAucHEtY2FyZF9fY29udGVudCB7CiAgcGFkZGluZzogMC41ZW07Cn0KLmIzbGNlOTUgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kOwp9Ci5iM2xjZTk2IHsKICBtYXJnaW4tbGVmdDogMWVtOwp9Ci5iM2xjZTk3IHsKICBtYXJnaW4tYm90dG9tOiAxZW07Cn0KLmIzbGNlOTggewogIGRpc3BsYXk6IGZsZXg7CiAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZDsKICB3aWR0aDogMTAwJTsKfQouYjNsY2U5OSB7CiAgcGFkZGluZzogMDsKfQ==';
export var arrowLeftPx = 'var(--b3lce90)';
export var arrowTopPx = 'var(--b3lce91)';
export var dialogBody = 'b3lce97';
export var dialogContentArrow = 'b3lce92';
export var dialogContentCard = 'b3lce94';
export var dialogContentHeader = 'b3lce98';
export var dialogContentHeaderButton = 'b3lce99';
export var dialogFooter = 'b3lce95';
export var dialogManagerSubmitBtn = 'b3lce96';
export var dialogWrapper = 'b3lce93';