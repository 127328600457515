import { useCallback, useEffect, useState } from 'react'

export const useBroadcastSubscription = <T = Record<string, string>>(): {
  postMessage: T
  sendParentMessage: (msg: any) => void
} => {
  const [postMessage, setPostMesage] = useState<T>(null)

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      if (!event.isTrusted) return

      if (Array.isArray(event.data)) {
        const updatedPostMessage = event.data.reduce(
          (prev, e) => {
            if (e.type !== undefined && e.value !== undefined) {
              prev[e.type] = e.value
            }
            return prev
          },
          { ...postMessage },
        )
        setPostMesage(updatedPostMessage)
      }
    },
    [postMessage],
  )

  useEffect(() => {
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [messageHandler])

  const sendParentMessage = useCallback((msg: any) => {
    if (window !== window.top && window?.parent?.postMessage) {
      // Ensure you send an array if your protocol expects it
      setTimeout(() => {
        window.parent.postMessage(Array.isArray(msg) ? msg : [msg], '*')
      }, 0)
    }
  }, [])

  useEffect(() => {
    if (window !== window.top && window?.parent?.postMessage) {
      // Define the callback function
      const callback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList' || mutation.type === 'subtree') {
            // Calculate the height of the page
            const pageHeight = document.body.scrollHeight

            window.parent.postMessage(
              [{ type: 'setHeight', value: `${pageHeight}px` }],
              '*',
            )
          }
        }
      }

      // Create a MutationObserver instance
      const observer = new MutationObserver(callback)

      // Options for the observer (what types of mutations to observe)
      const config = { attributes: true, childList: true, subtree: true }

      // Start observing the target node for configured mutations
      observer.observe(document.body, config)

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return { postMessage, sendParentMessage }
}
