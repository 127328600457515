import { TypePolicies } from '@apollo/client'
import { hashKeyArgs, cleanProperty } from './helpers'
import { offsetLimitPagination } from '../helpers/pagination'
import { sortByString } from '@palqee/utils'
import { setFullNameType } from '../lib'

export const company = (): TypePolicies => {
  return {
    Representative: {
      fields: {
        fullName: setFullNameType(),
      },
    },
    NamespaceCompanyMutation: {
      merge: true,
    },
    NamespaceCompanyQuery: {
      merge: true,
      fields: {
        positionsGet: {
          keyArgs: hashKeyArgs,
          read: (positionsGet, { readField }) => {
            if (positionsGet) {
              const { positions = [] } = positionsGet

              // sort
              return {
                ...positionsGet,
                positions: [...positions].sort((a, b) => {
                  return sortByString(false)(
                    readField('name', a),
                    readField('name', b),
                  )
                }),
              }
            }

            return positionsGet
          },
        },
        departmentsGet: {
          keyArgs: hashKeyArgs,
          read: (departmentsGet, { readField }) => {
            if (departmentsGet) {
              const { departments = [] } = departmentsGet

              // sort
              return {
                ...departmentsGet,
                departments: [...departments].sort((a, b) => {
                  return sortByString(false)(
                    readField('name', a),
                    readField('name', b),
                  )
                }),
              }
            }

            return departmentsGet
          },
        },
        audiencesGet: offsetLimitPagination({
          field: 'audiences',
          keyArgs: ({ input }) => {
            const { filter, order, search } = input
            const inputArg = {
              input: {
                filter,
                ...cleanProperty(search, 'search'),
                ...cleanProperty(order, 'order'),
              },
            }
            return hashKeyArgs(inputArg)
          },
        }),
      },
    },
  }
}
