import FileDownload from 'js-file-download'
import axios from 'axios'
import axiosClient from '@api/axios'

export const documentDownloadFile = async (
  file,
): Promise<{ data: string; contentType: string } | void> => {
  const { fileUrl, fileName, fileType } = file
  const data = ''
  const contentType = ''
  try {
    // due to some issues with the the presigned url redirect
    // a second request is required with no auth header
    const awsS3PresignedUrl = await axiosClient.get(fileUrl)
    const res = await axios.get(awsS3PresignedUrl?.data?.data?.url, {
      responseType: 'blob',
    })
    FileDownload(res.data, `${fileName}${fileType}`)
  } catch (error) {
    console.error(error)
  }

  return {
    contentType,
    data,
  }
}
