import { useFloatingUiContext } from '@palqee/utils'
import { PopperContentProps } from './types'
import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { arrowLeftPx, arrowTopPx, popperContentArrow } from './popper.css.ts'
import { forwardRef } from 'react'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { Overlay } from './overlay.tsx'

const PopperContent = forwardRef<HTMLDivElement, PopperContentProps>(
  (props, forwardedRef) => {
    const {
      useModal,
      useOverlay,
      usePortal = true,
      hideOnClick,
      useArrow,
      ...restProps
    } = props
    const { context: floatingContext, ...context } = useFloatingUiContext()
    const ref = useMergeRefs([context.refs.setFloating, forwardedRef])

    const { arrowRef, placement, middlewareData, strategy, x, y } = context
    const { x: arrowX, y: arrowY } = middlewareData?.arrow ?? {}

    // arrow placement
    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]]

    const setArrowPx = (arrow: number) =>
      arrow !== null && arrow ? `${arrow}px` : ''

    const { style, floatingStyle, ...floatingProps } = context.getFloatingProps(
      {
        ...restProps,
        ref,
      },
    )

    const setFloatingStyle = () => {
      if (useModal) return undefined

      return {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
        ...(floatingStyle ?? {}),
        ...(style ?? {}),
        zIndex: floatingStyle?.zIndex ?? 300,
      }
    }

    const translate = {
      top: { translateY: 5 },
      bottom: { translateY: -5 },
      left: { translateX: 5 },
      right: { translateX: -5 },
    }[placement.includes('-') ? placement.split('-')[0] : placement]

    // This handler will be called on any click inside the content.
    const handleContentClick = () => {
      if (hideOnClick === true) {
        // Default behavior: close the popper
        context.setOpen(false)
      }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        // Prevent default to avoid scrolling when Space is pressed
        event.preventDefault()
        handleContentClick()
      }
    }

    const renderFocusManager = () => {
      return (
        <FloatingFocusManager context={floatingContext} returnFocus={false}>
          <motion.div
            initial={{ opacity: 0, ...translate }}
            exit={{ opacity: 0, ...translate }}
            animate={{ opacity: 1, translateX: 0, translateY: 0 }}
            {...floatingProps}
            style={setFloatingStyle()}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
          >
            <div
              onClick={handleContentClick}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
              style={{ outline: 'none' }}
            >
              {props.children}

              {useArrow && (
                <div
                  className={popperContentArrow}
                  style={{
                    ...assignInlineVars({
                      [arrowLeftPx]: setArrowPx(arrowX),
                      [arrowTopPx]: setArrowPx(arrowY),
                    }),
                    [staticSide]: '-5px',
                  }}
                  id="pq-dialog__content-arrow"
                  ref={arrowRef as any}
                />
              )}
            </div>
          </motion.div>
        </FloatingFocusManager>
      )
    }

    const renderFloating = () => {
      if (useOverlay) {
        return <Overlay>{renderFocusManager()}</Overlay>
      }

      return renderFocusManager()
    }

    const renderPortal = () => {
      if (usePortal) {
        return <FloatingPortal>{renderFloating()}</FloatingPortal>
      }

      return renderFloating()
    }

    return (
      <AnimatePresence>
        {floatingContext.open && renderPortal()}
      </AnimatePresence>
    )
  },
)

export { PopperContent }
