// @todo map keys to actual
// backend values ie. DocumentFileNameAlreadyExists
export enum ExceptionCode {
  USERNAME_EXISTS = 'UsernameExistsException',
  USER_NOT_FOUND = 'UserNotFoundException',
  NOT_AUTHORIZED = 'NotAuthorizedException',
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  UNIQUE_VIOLATION = 'UniqueViolationError',
  INVALID_PARAMETER = 'InvalidParameterException',
  AUTH_ONE_TIME_TOKEN_NOT_FOUND = 'AuthOneTimeTokenNotFound',
  AUTH_ONE_TIME_TOKEN_EXPIRED = 'AuthOneTimeTokenExpired',
  AUTH_ONE_TIME_TOKEN_ALREADY_USED = 'AuthOneTimeTokenAlreadyUsed',
  AUTH_LOGIN_WRONG_CREDENTIALS = 'AuthLoginWrongCredentials',
  AUTH_SIGNUP_CONFIRM_FAILED_FOR_NON_PENDNG_USER = 'AuthSignUpConfirmFailedForNonPendingUser',
  AUTH_SIGNUP_CONFIRM_FAILED_FOR_ACTIVE_USER = 'AuthUserAlreadyActivated',
  DocumentFileNameAlreadyExists = 'DocumentFileNameAlreadyExists',
  AuthNotAuthenticated = 'AuthNotAuthenticated',
  AuthForbiddenTenant = 'AuthForbiddenTenant',
}

export enum ExceptionMessage {
  USER_DISABLED = 'User is disabled.',
}
export interface GraphQlError {
  extensions: {
    exception: {
      code: ExceptionCode
      name: ExceptionCode
      palqeeErrorCode?: ExceptionCode
    }
  }
}

export const doesExceptionCodeExistInGraphqlErrors = (
  graphqlErrors: GraphQlError[],
  exceptionCode: ExceptionCode,
): boolean => {
  return graphqlErrors.some(
    (error) => error?.extensions?.exception?.palqeeErrorCode === exceptionCode,
  )
}

export const doesExceptionNameExistInGraphqlErrors = (
  graphqlErrors: GraphQlError[],
  exceptionCode: ExceptionCode,
): boolean => {
  for (let i = 0; i < graphqlErrors.length; i++) {
    const graphqlErr = graphqlErrors[i]
    if (graphqlErr?.extensions?.exception?.name === exceptionCode) return true
  }

  return false
}

export const getGraphQlErrorsCode = (graphQlErrors: GraphQlError[]) => {
  return (
    graphQlErrors?.[0]?.extensions?.exception?.palqeeErrorCode ??
    (graphQlErrors?.[0]?.extensions as any)?.code
  )
}

export const getGraphQLErrorCodeFromError = (error) => {
  return getGraphQlErrorsCode(error?.graphQLErrors)
}

export const doesExceptionPalqeeErrorCodeExistInGraphqlErrors = (
  graphqlErrors: GraphQlError[],
  exceptionCode: ExceptionCode,
): boolean => {
  return graphqlErrors.some(
    (error) => error?.extensions?.exception?.palqeeErrorCode === exceptionCode,
  )
}
