import { useMemo, useRef, useState } from 'react'
import {
  useFloating,
  autoUpdate,
  useDismiss,
  useHover,
  safePolygon,
  useClick,
  useRole,
  useInteractions,
} from '@floating-ui/react'
import { arrow } from '@floating-ui/dom'
import { IFloatingUiProps, IUseFloatingReturn } from './types'

// @todo move this to @palqee/ui
export const useFloatingUi = (props: IFloatingUiProps): IUseFloatingReturn => {
  const {
    placement = 'left-end',
    strategy = 'absolute',
    enableHover = false,
    enableClick = true,
    defaultIsOpen = false,
    enableDismiss = true,
    enableAncestorScrollDismiss = false,
    middleware,
    role,
    open: controlledOpen,
    onOpenChange: controlledOnOpenChange,
    ...options
  } = props

  // track when float is enabled
  const enabled = enableClick || enableHover

  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()
  const [uncontrolledOpen, uncontrolledOnOpenChange] = useState(defaultIsOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = controlledOnOpenChange ?? uncontrolledOnOpenChange

  const arrowRef = useRef<HTMLDivElement>(null)
  const element = !arrowRef.current ? undefined : (arrowRef.current as Element)

  const buildMiddleware = () => {
    const middlewareconfig = []

    if (middleware) {
      middlewareconfig.push(...middleware)
    }

    if (element) {
      middlewareconfig.push(
        arrow({
          element,
        }),
      )
    }

    return middlewareconfig
  }

  const floatingData = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
    middleware: buildMiddleware(),
    ...options,
  })

  const context = floatingData.context

  const dismiss = useDismiss(context, {
    enabled: enableDismiss,
    outsidePressEvent: 'mousedown',
    ancestorScroll: enableAncestorScrollDismiss,
  })

  const hover = useHover(context, {
    enabled: enableHover,
    delay: {
      open: 0,
      close: 300,
    },
    move: false,
    handleClose: safePolygon(),
  })

  const click = useClick(context, {
    enabled: enableClick,
    ignoreMouse: enableHover,
  })

  const roleUi = useRole(context, { role })

  const interactions = useInteractions([click, hover, dismiss, roleUi])

  return useMemo<IUseFloatingReturn>(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...floatingData,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
      enabled,
      role,
    }),
    [
      open,
      setOpen,
      interactions,
      floatingData,
      arrowRef,
      labelId,
      descriptionId,
      enabled,
      role,
    ],
  )
}
