import { breakPoint } from '@common/utils/breakpoints'

// colors
export const fadedGrey = '#eff1f5'
export const darkGrey = '#f4f4f7'
export const darkerGrey = '#676767'
export const opaqueDarkGrey = 'rgba(63, 109, 180, 0.04)'
export const outlineBtnBorder = '#1a3154'
export const darkBlack = '#0d1331'
export const black = '#3a3a3a'
export const darkWhite = '#f8f9fa'

export const white = '#fff'

export const successGreen = '#51d19e'
export const darkGreen = '#42bb8b'
export const translucentGrey = 'rgba(203, 208, 223, 0.8)'

export const secondaryRed = '#ffebeb'
export const black1 = '#161b2d'
export const black2 = '#2a3047'
export const grey2 = '#b7bccb'

// theme colours

export const primaryLightBlue = '#3F6DB4'
export const primaryDarkBlue = '#1A3154'
export const primaryGreen = '#74D9B1'
export const primaryYellow = '#FFD13A'

// secondary colours
export const secondaryGreen = '#f1fbf7'

// form colours
export const controlDefaultGrey = 'rgba(203,208,223,0.3)'
export const controlDefaultGreyLight = 'rgba(203,208,223,0.15)'

export const controlErrorRed = 'rgba(255,105,109,0.5)'
export const brightErrorRed = 'rgba(255,105,109,1)'

// avatar bubble colours
export const red1 = '#DD7D7D'
export const green1 = '#A2D788'
export const blue1 = '#6594DD'
export const purple1 = '#B488D7'
export const red2 = '#DD7D7D'
export const green2 = '#88D7A3'
export const blue2 = '#6579DD'
export const purple2 = '#D788D7'
export const red3 = '#DDBE7D'
export const green3 = '#5FC7BE'
export const blue3 = '#7C65DD'
export const purple3 = '#D788AC'
export const red4 = '#CDD788'
export const green4 = '#57C2DB'
export const blue4 = '#9388D7'
export const purple4 = '#DF909B'

export const breakPointSm = breakPoint('small')
export const breakPointMd = breakPoint('medium')
export const breakPointLg = breakPoint('large')
export const breakPointXl = breakPoint('xLarge')
export const breakPoint2Xl = breakPoint('2XLarge')
export const breakPoint3Xl = breakPoint('3XLarge')

/**
 * New colors as per figma design
 * https://www.figma.com/file/1uhObitpxl2bLefiOqwifv/%F0%9F%97%82-Tasks?node-id=2572%3A148603
 */

// gauge colors
export const gaugeSuccess = '#10AC6F'
export const gaugeWarning = '#F3A40A'
export const gaugeNormal = '#bebebe'

// fonts
export const systemFont = "'Poppins', sans-serif"

export const green10 = '#F1FBF7'
export const green40Accent = '#10AC6F'
export const green50 = '#009058'

export const darkBlue50 = '#1A3154'
export const blue50 = '#2A579C'
export const blue10 = '#EFF3F9'
export const blue20 = '#DDE9FB'
export const blue30 = '#7A9DD6'
export const blue40a = '#3F6DB4'

export const red50 = '#F1565B'
export const red20 = '#FFCACC'
export const red40a = '#FF696D'
export const red10 = '#FFF5F5'
export const red30 = '#FF8B8E'

export const gray10 = '#FBFBFB'
export const gray20 = '#F4F4F7'
export const gray30 = '#E9EAF0'
export const gray40 = '#CBD0DF'
export const gray50 = '#B7BCCB'
export const gray60 = '#8A91A5'
export const gray70 = '#4B526B'
export const gray90 = '#161B2D'

export const yellow50 = '#E38800'
export const yellow40Accent = '#F3A40A'
export const yellow = '#FFD13A'
export const yellow10 = '#FFFAEB'

export const primaryBlue = blue50
export const primaryRed = red50

export const primaryBlueFocused = blue40a
export const primaryRedFocused = red40a

export const secondaryBlue = blue10
export const fadedBlue = blue10

export const fadedWhite = '#cbd0df'

export const grey1 = gray30
export const controlFocusedBlue = primaryBlue

export const blueAzure = '#5387d7'
export const azureSoft = '#F8F4FC'

export const yellow1 = '#f3a40a'
export const pink1 = '#d175d0'
export const violet1 = '#6366c7'
export const violetSoft = '#F2F3FA'

export const ochre = '#D6AC56'
export const ochreSoft = '#FCF8F2'

export const blueTiffani = '#4abeda'
export const tiffaniSoft = '#EEF9FB'

export const mainBackground = gray10
export const secondaryGrey = gray40
export const lightGrey = gray60

export const gaugeDanger = red40a

export const fontColor = darkBlack
