import { useModal } from '@ebay/nice-modal-react'
import { MouseEvent, MouseEventHandler, useState } from 'react'
import { DialogManagerProps } from './types'

export const useDialogManager = (
  props: Omit<DialogManagerProps, 'children'>,
) => {
  const { afterClickConfirm, hideOnConfirm, payload, id } = props

  const [loading, setLoading] = useState(false)

  const modal = useModal(id)

  const setOpen = (open: boolean) => {
    if (open) {
      modal.show()
    } else {
      modal.remove()
    }
  }

  const hideModalHandler = () => {
    if (hideOnConfirm) modal.remove()
  }

  const onClickHandler = async (e: MouseEvent<HTMLElement>) => {
    setLoading(true)

    try {
      if (afterClickConfirm) await afterClickConfirm(e, payload, { modal })

      if (payload) modal.resolve(payload)

      hideModalHandler()
    } catch (error) {
      modal.reject(error)
    }

    setLoading(false)
  }

  const handleConfirm = (
    onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<void> | void,
  ): MouseEventHandler<HTMLButtonElement> => {
    return (e: MouseEvent<HTMLButtonElement>) => {
      void (async () => {
        if (onClick) {
          await onClick(e)
          hideModalHandler()
        } else {
          await onClickHandler(e)
        }
      })()
    }
  }

  return {
    loading,
    open,
    modal,
    handlers: {
      handleConfirm,
      onClickHandler,
      hideModalHandler,
      setOpen,
    },
  }
}
